import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { SingleBeer } from '../templates/Beer';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';

const BeerListStyles = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  div img {
    width: 100%;
    height: 200px;
    font-size: 15px;
  }
  div a {
    cursor: default;
    pointer-events: all;
    text-decoration: none;
    &:hover {
      color: green;
      text-decoration: underline green;
    }
  }
`;

const BeerList = ({ beers }) => (
  <BeerListStyles>
    {beers.map((beer) => (
      <Fragment key={beer.id}>
        <SingleBeer beer={beer} />
      </Fragment>
    ))}
  </BeerListStyles>
);

const BeersPage = ({
  data: {
    allBeer: { beers, totalCount },
  },
  pageContext,
}) => (
  <>
    <SEO title={`Beers - We have ${totalCount} in stock`} />
    <h2 className="center">
      We have {totalCount > 1 ? '' : 'only '}
      {totalCount} Beer{totalCount > 1 ? 's' : ''} Available. Dine in Only!
    </h2>
    <Pagination
      base="beers"
      pageSize={parseInt(process.env.GATSBY_BEERS_PAGE_SIZE)}
      totalCount={totalCount}
      currentPage={pageContext.currentPage || 1}
    />
    <BeerList beers={beers} />
  </>
);
export default BeersPage;

export const query = graphql`
  query BeerQuery($skip: Int = 0, $pageSize: Int = 12) {
    allBeer(limit: $pageSize, skip: $skip, sort: { fields: name, order: ASC }) {
      beers: nodes {
        name
        id
        image
        price
        rating {
          average
          reviews
        }
      }
      totalCount
    }
  }
`;
